<template>
  <div 
    class="
    d-flex 
    flex-lg-row 
    flex-md-column 
    flex-grow-1">
    <div class="
      illustration-login 
      d-lg-flex
      d-md-none
      flex-column
      order-lg-1
      order-sm-2 order-2
      col-lg-6
      col-sm-12 
      col-12 p-0">
      <div class="m-auto">
        <img src="@/assets/illustration/illustration-login.svg" alt="">
      </div>
    </div>

    <div class="
      form-login-wrapper 
      d-flex
      flex-column
      order-lg-2
      order-sm-1 order-1
      col-lg-6
      col-sm-12 
      col-12 p-0
      bg-white">
      <div class="
        logo 
        ml-lg-auto 
        mx-lg-0 
        mx-md-auto 
        mx-sm-auto 
        mx-auto">
        <img src='@/assets/icons/ihs-logo.svg' alt="Logo ">
      </div>
      <div class="d-flex flex-column flex-grow-1">
        <div class="my-auto">
          <div class="text-center">
            <span class="
              font-32 
              font-nunito
              font-700 
              color-blue">
              Selamat Datang Kembali!
            </span>
          </div>

          <div class="text-center mb-60">
            <span class="font-20 font-roboto">
              Masuk kembali ke akun admin Inti Health Store
            </span>
          </div>

          <el-form 
            ref="cashierForm"
            :model="cashierForm" 
            :rules="cashierFormRules"
            @submit.native.prevent="submitForm()">
            <div class="mb-4">
              <span class="font-roboto font-14">
                Username atau email
              </span>
              <el-form-item 
                prop="username" 
                class="mb-0">
                <el-input
                  class="mt-2"
                  v-model="cashierForm.username"
                  placeholder="Masukan Username atau email">
                </el-input>
              </el-form-item>
            </div>
            <div class="password mb-40">
              <span class="font-roboto font-14">
                Password
              </span>
              <el-form-item 
                prop="password" 
                class="mb-0">
                <el-input
                  class="mt-2"
                  v-model="cashierForm.password"
                  :type="passwordVisibility ? 'text' : 'password'"
                  placeholder="Masukan Password">
                  <img 
                    slot="suffix" 
                    class="password__toggle-icon" 
                    :src="passwordIcon()" 
                    @click="togglePassword()"
                    alt="eye">
                </el-input>
              </el-form-item>
            </div>
            <div class="pt-3">
              <button 
                ref="cashierFormSubmit"
                type="submit"
                class="btn btn-primary py-3 w-100">
                Login
              </button>
            </div>
          </el-form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
export default {
  data(){
    const checkUsername = (rule, value, callback) => {
      const field = rule.field
      const input = value.charAt(value.length - 1)
      const letters = /^[A-Za-z._0-9._+@]+$/

      if (value === ' ') {
        this.cashierForm[field] = ''
      }

      if (!input.match(letters)) {
        this.cashierForm[field] = value.substring(0, value.length - 1)
      }

      if (value.length > 255) {
        this.cashierForm[field] = value.substring(0, value.length - 1)
      }

      callback()
    }

    const checkPassword = (rule, value, callback) => {
      const field = rule.field

      if (value.length > 255) {
        this.cashierForm[field] = value.substring(0, value.length - 1)
      }

      callback()
    }

    return{
      cashierForm: {
        username: '',
        password: ''
      },
      cashierFormRules: {
        username: [
          { required: true, message: 'Username wajib diisi', trigger: 'blur' },
          { validator: checkUsername, trigger: 'change'}
        ],
        password: [
          { required: true, message: 'Password wajib diisi', trigger: 'blur' },
          { validator: checkPassword, trigger: 'change'}
        ]
      },
      passwordVisibility: false
    }
  },
  methods: {
    ...mapActions('auth', ['loginCashier']),
    ...mapActions("cart", ["emptyCart", "getCart"]),
    ...mapActions("user", ["getUser", "getCustomer"]),
    togglePassword(){
      this.passwordVisibility = !this.passwordVisibility
    },
    passwordIcon(){
      if(this.passwordVisibility){
        return require('@/assets/icons/eye.svg')
      }else{
        return require('@/assets/icons/eye-slash.svg')
      }
    },
    
    async cashier(){
      try {
        const resp = await this.loginCashier({
          data: {
            ...this.cashierForm
          },
        })
        if (resp.data.status_code === 200) { 
          await this.getCustomer({phone_number: 111000})
           await this.getCart({id: 111000, headers: {
             Authorization: "Bearer " + resp.data.data.access_token
           }})
           await this.emptyCart({data: {phone_number: 111000}, headers: {
             Authorization: "Bearer " + resp.data.data.access_token
           }})
          await this.$router.push({name: 'Dashboard'})
          await location.reload();

        }
      } catch (error) {
           if (error.response.status === 422) {
            this.$message({
            showClose: true,
            customClass: "error-message",
            message: 'Maaf data username / password yang anda masukan salah',
            type: "error"
          });
        } else {
           this.$message({
            showClose: true,
            customClass: "error-message",
            message: error.response.data.message,
            type: "error"
          });
        }
      }
    },
    submitForm(){
      this.$refs.cashierFormSubmit.disabled = true

      this.$refs['cashierForm'].validate( async (valid) => {
        if (valid) {
          await this.cashier()
       
          this.$refs.cashierFormSubmit.disabled = false
        } else {
          this.$refs.cashierFormSubmit.disabled = false
          return false
        }
      })
    },
    resetForm() {
      this.$refs['cashierForm'].resetFields();
    },
  }
}
</script>

<style lang="scss">
.password{
  &__toggle-icon{
    padding: 0 16px;
    cursor: pointer;
  }
}
</style>

<style lang="scss" scoped>
.illustration-login{
  background: #006CB5;
}

.form-login-wrapper{
  padding: 23px 55px !important;
}

.color-blue{
  color: #006CB5;
}
</style>